import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "../Image/Image.css"


const Image = () => {
  const imageUrl = [
    "https://e0.pxfuel.com/wallpapers/968/268/desktop-wallpaper-computer-rain-pixel-live.jpg",
    "https://i.pinimg.com/originals/fe/2d/fb/fe2dfb30cd19d2936ab23a17f2419b69.jpg",
    "https://p0.piqsels.com/preview/274/446/504/night-star-sky-light.jpg",
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      {/* <Carousel prevLabel="" nextLabel="">
        {imageUrl.map((image) => (
          <Carousel.Item>
            <img style={{width:'1000px',height:'1000px'}}
              className=""
              src={image}
              alt="Luxury Bedroom"
            />
          </Carousel.Item>
        ))}
      </Carousel> */}

{/* <Carousel>
      <Carousel.Item>
        <img src="https://images.pexels.com/photos/1669799/pexels-photo-1669799.jpeg?cs=srgb&dl=pexels-vecislavas-popa-1669799.jpg&fm=jpg" alt="First slide" />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="https://media.istockphoto.com/id/1390233984/photo/modern-luxury-bedroom.jpg?s=612x612&w=0&k=20&c=po91poqYoQTbHUpO1LD1HcxCFZVpRG-loAMWZT7YRe4=" alt="Second slide" />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww" alt="Third slide" />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}


{/* <Carousel activeIndex={index} onSelect={handleSelect}>
{imageUrl.map((image) => (
          <Carousel.Item>
            <img style={{width:'100%',height:'500px',marginTop:'40px'}}
              className=""
              src={image}
              alt="Luxury Bedroom"
            />
          </Carousel.Item>
        ))}
      
    </Carousel> */}
 
 <Carousel activeIndex={index} onSelect={handleSelect}>
  {imageUrl.map((image, idx) => (
    <Carousel.Item key={idx}>
      <img
        style={{ width: '100%', height: '500px', marginTop: '40px' }}
        className=""
        src={image}
        alt="Luxury Bedroom"
      />
      <div className="carousel-caption">
        <h3>YolDo PGs</h3>
        <p>This is a description related to the image.</p>
      </div>
    </Carousel.Item>
  ))}
</Carousel>


 

    </>
  );
};

export default Image;
