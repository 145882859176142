import React, { useState } from 'react';
import "./Contact.css"; // Assuming you have a separate CSS file for styling
import { TbArrowsJoin2 } from "react-icons/tb";
import { IoLogoInstagram } from "react-icons/io5";
import { PiFacebookLogoBold } from "react-icons/pi";
import { SiWhatsapp } from "react-icons/si";
import { TbMessageCircleCancel } from "react-icons/tb";
import Footer from '../Footer/Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform form submission logic here, such as sending data to a server
    console.log(formData);
    alert('Submit')
  };

  return (
    <>
      <div className='img' style={{ backgroundImage: 'url("https://whiteklay.com/wp-content/uploads/2020/12/Contact-us-Main-Banner-1.jpg")' }}>
        <div>
          {/* <h1 className='contactus'>Contact US</h1> */}
        </div>
      </div>
      <div className="container">
        <div className="body">
          <div className="body-text">
            <h1>Contact US</h1>
            <br />
            <p><TbArrowsJoin2 style={{ color: 'red' }} /> Nulla pharetra eleifend tellus in molestie. In vel neque sit amet urna gravida blandit nec id massa. Phasellus eu aliquet augue. Quisque fringilla urna quam.</p>
            <div className="social-medias">
              <br />
              <p><IoLogoInstagram style={{ color: 'red' }} />  instagram</p>
              <p><PiFacebookLogoBold style={{ color: 'blue' }} />  facebook </p>
              <p><SiWhatsapp style={{ color: 'green' }} />  What's Up </p>
            </div>
          </div>
        </div>
        <div className="body">
          <h2><TbMessageCircleCancel />  Send A Message</h2>
          <hr style={{ color: 'blue' }} />
          <br />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                placeholder="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
                maxLength="10"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="E-mail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                name="description"
                placeholder="Message Here.."
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        
      </div>
      <Footer/>
    </>
  );
};

export default Contact;
