import React, { useState } from 'react';
// import ImageWithText from './ImageWithText';
import { FaFacebookMessenger } from 'react-icons/fa';
import Modal from 'react-modal';

import "../PGowners/PGowners.css"
import ImageWithText from './ImageWithText';



const defaultMessage = "Hello! I have a question.";

 
const Pgowners = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);

  };
 

  
   
  const openModal = () => {
    setIsOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
 

  const closeModal = () => {
    setIsOpen(false);
  };

  const sendMessage = () => {
    // Code to send message
    // For demo purposes, we'll just log it
    console.log(defaultMessage);
    closeModal();
  };


  return (
   
    <>
     <div className="Pgowners-container">
    <div className="app">
       <ImageWithText
          text={
            <>
              <h1 className="main-text">Book My PG...</h1>
              <p className="main-paragraph">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Sed faucibus consequat leo, quis aliquet ligula ultrices nec.
                Nam vitae magna sit amet odio bibendum bibendum. 
                Integer vel augue quis arcu dapibus convallis nec in purus.
                Nulla facilisi.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Sed faucibus consequat leo, quis aliquet ligula ultrices nec.
              </p>
              {expanded && (
          <p className="main-paragraph-add">
            Additional text content goes here. You can add more details  the PG owners or any other relevant information.
          </p>
          
          
          
        )}
        <button className="read-more-button" onClick={toggleExpanded}>
          {expanded ? 'Read Less' : 'Read More'}
        </button>
            </>
          }
          imageUrl="https://homebliss.in/wp-content/uploads/2021/10/Feature-New-Recovered.jpg"
        />
       
      </div>
    </div>
     <div>
      <button className="messenger-button" onClick={openModal} >
        <FaFacebookMessenger />
      </button>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Facebook Messenger"
        className="messenger-modal"
       
      >
        <h2>Send Message</h2>
        <p>{defaultMessage}</p>
        <button onClick={sendMessage}>Send Message</button>
        <button onClick={closeModal}>Close</button>
      </Modal>
      <div>
      
      </div>
     
    </div></>
  )
};

export default Pgowners;
