import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import "./About.css"
import { TbArrowsJoin2 } from "react-icons/tb";
import { Carousel } from 'react-bootstrap';

const About = () => {
  const imageUrl = [
    "https://images.hdqwalls.com/download/freedom-to-go-5k-8h-1440x900.jpg",
    "https://i.pinimg.com/originals/fe/2d/fb/fe2dfb30cd19d2936ab23a17f2419b69.jpg",
    "https://p0.piqsels.com/preview/274/446/504/night-star-sky-light.jpg",
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
    <div>
    <Carousel activeIndex={index} onSelect={handleSelect}>
  {imageUrl.map((image, idx) => (
    <Carousel.Item key={idx}>
      <img
        style={{ width: '100%', height: '500px', marginTop: '40px' }}
        className=""
        src={image}
        alt="Luxury Bedroom"
      />
      <div className="carousel-caption">
        <h3>YolDo PGs</h3>
        <p>This is a description related to the image.</p>
      </div>
    </Carousel.Item>
  ))}
</Carousel>
    {/* <div className='img' style={{ backgroundImage: 'url("https://hidabrowa.pl/wp-content/uploads/2015/07/suite-top-1024x683-1.jpg")' }}> */}
      <div>
      <h1 className='about'>About US</h1>
      </div>
      </div>
      <div class="container">
    <div class="body">
        <div class="body-text">
            <h1>LOREM IPSUM</h1>
            <h1>DOL -SITAMET</h1>
        </div>
    </div>
    <div class="body">
        <h2>Lorem ipsum dolor sit amet, consec</h2>
        <hr style={{ color: 'blue' }} />
        <br></br>
        <p>Donec bibendum massa metus, vel aliquet nunc varius eu. Curabitur nec scelerisque dui. Quisque mattis libero et enim ultricies gravida. Nulla ut commodo massa, eget tincidunt ligula. Vivamus eu ante tincidunt, fermentum risus nec, pharetra turpis. Donec rhoncus eros sed felis aliquet tincidunt. In consectetur tempor quam</p>
        <br></br>
        <h5><TbArrowsJoin2 style={{ color: 'red' }} />   Sed vitae facilisis nisi, in finibus lacus. Duis vel nulla orci....</h5>
        <br></br>
        <h5><TbArrowsJoin2 style={{ color: 'red' }} />     Sed vitae facilisis nisi, in finibus lacus. Duis vel nulla orci....</h5>
        <br></br>
        <h5> <TbArrowsJoin2 style={{ color: 'red' }} />   Sed vitae facilisis nisi, in finibus lacus. Duis vel nulla orci...</h5>
        <br></br>
        <h5><TbArrowsJoin2 style={{ color: 'red' }} />   Sed vitae facilisis nisi, in finibus lacus. Duis vel nulla orci..</h5>
    </div>
</div>

    <Footer/>
    </>
  )
}

export default About
