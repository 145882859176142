import React, { useState } from "react";
import "../Blog/Blog.css";
import { IoLocationSharp } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

const dollImages = [
  "https://gsh-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/no-broker-mumbai/PRT555/room-on-rent-in-mumbai/pg-in-santacruz-east/RM3244/flat-on-rent-in-vakola-2.jpg",
  "https://gsh-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/no-broker-mumbai/PRT555/room-on-rent-in-mumbai/pg-in-santacruz-east/RM3244/flat-on-rent-in-vakola-2.jpg",
  "https://gsh-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/no-broker-mumbai/PRT555/room-on-rent-in-mumbai/pg-in-santacruz-east/RM3244/flat-on-rent-in-vakola-2.jpg",
  "https://gsh-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/no-broker-mumbai/PRT555/room-on-rent-in-mumbai/pg-in-santacruz-east/RM3244/flat-on-rent-in-vakola-2.jpg",
  "https://gsh-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/no-broker-mumbai/PRT555/room-on-rent-in-mumbai/pg-in-santacruz-east/RM3244/flat-on-rent-in-vakola-2.jpg",
  "https://gsh-cdn.sgp1.cdn.digitaloceanspaces.com/assets/img/no-broker-mumbai/PRT555/room-on-rent-in-mumbai/pg-in-santacruz-east/RM3244/flat-on-rent-in-vakola-2.jpg",

  " https://www.addressofchoice.com/aoc_assets/blog/1567247428_Staying_in_a_PG_Accommodation_in_Bangalore_of_Boys_and_Girls.jpg",
  " https://4.imimg.com/data4/XP/JB/MY-29402471/pg-rooms-500x500.jpg",
];
const Blog = () => {
  const accoptions = [
    "Select Share Type",
    "All",
    "1 Share",
    "2 Share",
    "3 Share",
    "4 Share",
  ];
  const accoptions1 = ["Select Gender", "ALL", "Boys", "Girls","Co-Living"];
  const accoptions2 = ["Select Room Type", "Full Room", "1 BHK","2 BHK", "3 BHK"];
  const accoptions3 = ["Select Range Type", "ALL", "5000 - 6000","6000 - 7500", "7500 - 10000"];
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOption3, setSelectedOption3] = useState("");

  // const [clientName, setClientName] = useState('');
  // const [Blogs, setBlogs] = useState([]);

  const handleOptionChange = (accoptions) => {
    setSelectedOption(accoptions);
  };

  const handleOptionChange1 = (accoptions1) => {
    setSelectedOption1(accoptions1);
  };
  const handleOptionChange2 = (accoptions2) => {
    setSelectedOption2(accoptions2);
  };
  const handleOptionChange3 = (accoptions3) => {
    setSelectedOption3(accoptions3);
  };
  return (
    <>
    <div>
      <div className="account-statement-container">
        <h1 className="account-statement-title">RooMs Available..!</h1>
        <br></br>
        <select
          title="types"
          className="account-option-select"
          value={selectedOption}
          onChange={(e) => handleOptionChange(e.target.value)}
        >
          {accoptions.map((accoption, index) => (
            <option key={index} value={accoption}>
              {accoption}
            </option>
          ))}
        </select>
        <select
          title="Option Types"
          className="account-option-select"
          value={selectedOption1}
          onChange={(e) => handleOptionChange1(e.target.value)}
        >
          {accoptions1.map((accoption, index) => (
            <option key={index} value={accoption}>
              {accoption}
            </option>
          ))}
        </select>
        <select
          title="Option Types"
          className="account-option-select"
          value={selectedOption2}
          onChange={(e) => handleOptionChange2(e.target.value)}
        >
          {accoptions2.map((accoption, index) => (
            <option key={index} value={accoption}>
              {accoption}
            </option>
          ))}
        </select>
        <select
          title="Option Types"
          className="account-option-select"
          value={selectedOption3}
          onChange={(e) => handleOptionChange3(e.target.value)}
        >
          {accoptions3.map((accoption, index) => (
            <option key={index} value={accoption}>
              {accoption}
            </option>
          ))}
        </select>
        {/* <input
        className="account-search-input"
        type="text"
        placeholder="Search by Client"
        value={clientName}
        onChange={handleClientNameChange}
      /> */}
     </div>
     </div>
      <div className="home-decors-cont">
        {dollImages.map((img, id) => {
          return (
            <Link key={id} to="/image">
            <div key={id} className="paint-img">
              <img src={img} alt="abc" />
              <h3 class="white-house-pg">YolDo House PG</h3>
              <hr className="hre"></hr>
              
              <h5 class="jp-nagar">
                <IoLocationSharp className="icon" />
                JP Nagar...!
                <p className="rupee-amount">
                <FaRupeeSign className="icon" />
                9000
                {/* <button>Book</button> */}
              </p>
              </h5>
              
              
              
            </div>
      </Link>

          );
        })}
      </div>
      <Footer />
    </>
  );
};

export default Blog;
