import React from "react";
import Searchbar from "../Searchbar/Searchbar";
import Footer from "../Footer/Footer";
import "./Herdoy.css";
import { FaArrowsAltH } from "react-icons/fa";
import { FcLike } from "react-icons/fc";
import Image from "../Image/Image";
import Informationcard from "../InformationCard/Informationcard";

import Pgowners from "../PGowners/Pgowners";

const Herdoy = () => {
  return (
    <>
      <div
        className="img"
        style={{
          backgroundImage:
            'url("https://png.pngtree.com/background/20231018/original/pngtree-3d-rendering-of-blue-living-room-with-a-gray-sofa-picture-image_5597125.jpg")',
        }}
      >
        <div>
          <div className="media-text">
            <h1 className="text">
              A Home
              <font color="black">-like</font>
              <font color="red"> Experience</font>
              <font color="rgb(36, 30, 88)"></font>
            </h1>
          </div>
          <h1 className="text-alian">
            Tiolard
            <font color="black"> for</font>
            <font color="black"> you</font>
            <font color=" brown"> !</font>
          </h1>
          <h2 className="text-alian-h3">Best and Afforadable PGs ....!</h2>
          <FaArrowsAltH className="arrow-icon" />
        </div>

        {/* <Searchbar /> */}
      </div>

      <Informationcard />
      <div>
        <Image />
        <br></br>
        <div class="container">
          <div className="bodt-text">
            <img
              src="https://e0.pxfuel.com/wallpapers/335/92/desktop-wallpaper-beach-houses-beach-bungalow.jpg"
              alt="Businessman touching icons"
            />
          </div>
          <br></br>
          <div className="bodt-text-para">
            <h2>Lorem ipsum dolor sit amet, consec !</h2>
            <hr style={{ color: "red" }} />
            <br></br>
            <h4>
              Post Admin <FcLike /> ..!
            </h4>
            <br></br>
            <p className="para-graph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam arcu
              odio, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              arcu odio, scelerisque a vehicula vitae, auctor sit amet lectus.
            </p>
            <br></br>
            <p className="para-graph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam arcu
              odio, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              arcu odio, scelerisque a vehicula vitae, auctor sit amet lectus.
            </p>
            <br></br>
            {/* <p className="para-graph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam arcu
              odio, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              arcu odio, scelerisque a vehicula vitae, auctor sit amet lectus.
            </p> */}
          </div>
        </div>
      </div>
      <div>
        <div className="card-container">
          <div className="card">
            <img
              src="https://c4.wallpaperflare.com/wallpaper/582/443/760/white-design-room-sofa-wallpaper-preview.jpg"
              alt="Card 1"
            />
            <div className="card-text">
              <h2>Living Comfortably..!</h2>
              <p>Advantages of choosing PGs from Delhi....!</p>
            </div>
          </div>
          <div className="card">
            <img
              src="https://i.pinimg.com/originals/25/aa/99/25aa990a00c6fc659f7548d644c1e7ed.png"
              alt="Card 2"
            />
            <div className="card-text">
              <h2>Living Comfortably..!</h2>
              <p>Advantages of choosing PGs from Mumbai....!</p>
            </div>
          </div>
          <div className="card">
            <img
              src="https://i.pinimg.com/736x/e6/4a/55/e64a5569f1ba124fd73347826acdcdf0.jpg"
              alt="Card 3"
            />
            <div className="card-text">
              <h2>Living Comfortably..!</h2>
              <p>Advantages of choosing PGs from World....!</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Pgowners />
      </div>
      {/* <div className="custom-container">
        <div className="image-container">
          <img
            src="https://www.gopgo.in/assets/new_ui/webp_images/homeDownloadPhone-d15d2dad198b19bba2bc8a8be9cb6d7a964a37b90b672e972325f8009aebcb4b.webp"
            alt="Description of the image"
          />
        </div>
        <div className="text-container">
          <h2 className="mb-0">
            {" "}
            Find Your
            <span>
              {" "}
              <img
                src="https://www.gopgo.in/assets/new_look/homeDownloadDoit-22335c8dd24c7d058f98d453cbce1a8112c53fe683b0c40160be33b518e366a1.png"
                className="img-fluid"
                alt="Description of the image"
              />
            </span>
          </h2>
          <h1 className="mb-0"> Home Now !</h1>
          <h2 className="mb-0" style={{ color: "red", fontSize: "35px" }}>
            Book your next adventure today.
          </h2>
          <div>
            <img
              src="https://freelogopng.com/images/all_img/1664287128google-play-store-logo-png.png"
              alt="Businessman touching icons"
              style={{ width: "200px", height: "auto" }}
            />
          </div>
        </div>
      </div> */}
      <div className="custom-container">
  <div className="image-container">
    <img
      src="https://www.gopgo.in/assets/new_ui/webp_images/homeDownloadPhone-d15d2dad198b19bba2bc8a8be9cb6d7a964a37b90b672e972325f8009aebcb4b.webp"
      alt="Description"
      className="img-fluid"
    />
    
  </div>
  <div className="text-container">
    <h2 className="mb-0">
      Find Your <span> <img
        src="https://www.gopgo.in/assets/new_look/homeDownloadDoit-22335c8dd24c7d058f98d453cbce1a8112c53fe683b0c40160be33b518e366a1.png"
        alt="Description"
        className="img-fluid-img"
      /> </span>
    </h2>
    <h1 className="mb-0"> Home Now !</h1>
    <h1 className="mb-0" >
      Book your next adventure today.
    </h1>
    <div className="store-logos">
  <img
    src="https://freelogopng.com/images/all_img/1664287128google-play-store-logo-png.png"
    alt="Google Play Store Logo"
    className="store-logo"
  />
  <img
    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/800px-Download_on_the_App_Store_Badge.svg.png"
    alt="App Store Logo"
    className="store-logo-end"
  />
</div>

  </div>
</div>


      <br></br>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Herdoy;
