// Navbar.js
import React, { useState } from "react";
import { Link } from 'react-router-dom'; 
import { DiApple } from "react-icons/di";
import { IoPerson } from "react-icons/io5";
import './navbar.css';

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  

  const navToggle = () => {
    setActive(prevActive => (prevActive === "nav__menu" ? "nav__menu nav__active" : "nav__menu"));
    setIcon(prevIcon => (prevIcon === "nav__toggler" ? "nav__toggler toggle" : "nav__toggler"));
  };

  return (
    <nav className="nav">
      <div className="set">
        <Link to="/" className="nav__brand">
          YolDo PG'S
        </Link>
      </div>

      <ul className={active}>
        <li  onClick={navToggle} className="nav__item">
          <Link to="/" className="nav__link">
            Home
          </Link>
        </li>
        <li  onClick={navToggle} className="nav__item">
          <Link to="/about" className="nav__link">
            About
          </Link>
        </li>
        {/* <li className="nav__item">
          <Link to="/pgowners" className="nav__link">
            PGOwners
          </Link>
        </li> */}
        <li onClick={navToggle} className="nav__item">
          <Link to="/contact" className="nav__link">
            ContactUs
          </Link>
        </li>
        <li onClick={navToggle} className="nav__item">
          <Link to="/blog" className="nav__link">
            Rooms
          </Link>
        </li>
        <li onClick={navToggle} className="nav__item">
          <Link to="/contact" className="nav__link">
          <IoPerson />  Login
          </Link>
        </li>

       
      </ul>
       <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      
    </nav>
  );
}

export default Navbar;

// style={{ width: isOpen ? "60px" : "200px", transition: "all 0.5s" }}