import React from 'react';
import "../PGowners/PGowners.css"

class ImageWithText extends React.Component {
  render() {
    const { imageUrl, text } = this.props;

    return (
      <div className="image-with-text-container">
         <div className="text">{text}</div>
        <img src={imageUrl} alt="" className="image" />
       
      </div>
    );
  }
}

export default ImageWithText;



