import React from 'react';
import "../InformationCard/Informationcard.css";
import { FaWifi } from "react-icons/fa";
import { SlPower } from "react-icons/sl";
import { BsFillBusFrontFill } from "react-icons/bs";
import { LuParkingCircle } from "react-icons/lu";
import { IoWater } from "react-icons/io5";
import { GiLargePaintBrush } from "react-icons/gi";

const Informationcard = () => {
  return (
    <div className="information-card">
      <div className='item'>
        <FaWifi className='icon-e' />  <h2 className='mad-e'> Wifi..</h2>
      </div>
      <div className='item'>
        <SlPower className='icon-f'/> <h2> Powerbackup..</h2>
      </div>
      <div className='item'>
        <BsFillBusFrontFill className='icon-g' /> <h2> Travelling..</h2>
      </div>
      <div className='item'>
        <LuParkingCircle  className='icon-h'  /> <h2> Parking..</h2>
       
      </div>
      <div className='item'>
        <IoWater className='icon-i' /> <h2> Purified Water..</h2>
      </div>
      <div className='item'>
        <GiLargePaintBrush className='icon-j' /> <h2>Cleaning..</h2>
      </div>
    </div>
  );
}

export default Informationcard;
