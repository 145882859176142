import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Herdoy from './components/Herdoy/Herdoy'
import About from './components/About/About'

import Contact from './components/Contact/Contact'

import Blog from './components/Blog/Blog'
import Image from './components/Image/Image'
import Pgowners from './components/PGowners/Pgowners'






const Router = () => {
  return (
    <div>
    <Routes>
    <Route path="/" element={<Herdoy/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/contact" element={< Contact/>}/>
    <Route path="/pgowners" element={< Pgowners/>}/>
    <Route path="/blog" element={< Blog/>}/>
    <Route path="/image" element={< Image/>}/>
    
    </Routes>
    </div>
  )
}

export default Router
  