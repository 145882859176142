import React from "react";
import Navbar from "./components/navbar";
import {BrowserRouter,} from "react-router-dom";
import Router from "./Router";

function App() {
  return (
    <>
    
      <BrowserRouter>
      <Navbar />
      
     <Router/>
      
      </BrowserRouter>
    </>
  );
}

export default App;
