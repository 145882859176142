import React from "react";
import "./footer.css";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { HiPhone } from "react-icons/hi2";
import { IoLogoInstagram } from "react-icons/io5";
import { PiFacebookLogoBold } from "react-icons/pi";
import { SiWhatsapp } from "react-icons/si";
import PgLogo from '../../Assets/pg-logo.png'
const Footer = () => {
  return (
    <div className="footer-container-data">
      <div className="footer-container">
        <div className="logo">
          {/* <img
          src="https://cdn.pixabay.com/photo/2012/04/16/11/34/shopping-35594_1280.png"
          alt="abc"
        /> */}
          <Link to="/" className="nav__brand">
            <img src={PgLogo} alt="YolDo PG'S" />
          </Link>
        </div>
        <div className="address-cont">
          <h3>Address</h3>
          <hr className="hr"></hr>

          <h2 className="h2">Branch : Marathahalli</h2>
          <p>
            <FaLocationDot /># 90b, near Innovative Multiplex, Marathahalli,
          </p>
          <p>Village, Marathahalli, Bengaluru, Karnataka 560037.</p>

          <p>
            <HiPhone className="icon" /> + 91-99191992920
          </p>
        </div>

        <div className="theme-links">
          <h3>Support</h3>
          <hr className="hr"></hr>

          <Link to="/herdoy" className="nav__link">
            Home
          </Link>

          <p>
            <Link to="" className="nav__link">
              PG Owners
            </Link>
          </p>
          <p>
            <Link to="/about" className="nav__link">
              About
            </Link>
          </p>
          <p>
            <Link to="contact" className="nav__link">
              Contact Us
            </Link>
          </p>
          <p>
            <Link to="/blog" className="nav__link">
              Blog
            </Link>
          </p>
        </div>
        <div className="social-medias">
          <h3>Social Media</h3>
          <hr className="hr"></hr>
          <br></br>
          <p>
            <IoLogoInstagram style={{ color: "red" }} /> instagram
          </p>
          <p>
            <PiFacebookLogoBold style={{ color: "blue" }} /> facebook{" "}
          </p>
          <p>
            <SiWhatsapp style={{ color: "green" }} /> What's Up{" "}
          </p>
        </div>
      </div>
      {/* <hr></hr>
      <div>
        <p className="paragrph">PG</p>
      </div> */}
    </div>
  );
};

export default Footer;

// import React from "react";

// const Footer=()=>{
// return (
//   <div className="footer">
//     <div className="sb_footer section_padding">
//       <div className="sb_footer-links">
//         <div className="sb_footer-links-div">
//           <h4>For Business</h4>
//           <a href="/employer">
//             <p>Employer</p>
//           </a>
//           <a href="/healthplan">
//             <p>Healthplan</p>
//           </a>
//           <a href="/individuals">
//             <p>Individuals</p>
//           </a>

//         </div>
//         <div className="sb_footer-links_div">
//         <h4>Resource</h4>
//         <a href="/resourceenter">
//             <p>Resource Center</p>
//           </a>
//           <a href="/resourceenter">
//             <p>Resource Center</p>
//           </a>
//           <a href="/resourceenter">
//             <p>Resource Center</p>
//           </a>

//           </div>
//           <div className="sb_footer-links_div">
//         <h4>compay</h4>
//         <a href="/about">
//             <p>About Center</p>
//           </a>
//           <a href="/careers">
//             <p>career</p>
//           </a>
//           <a href="/blo">
//             <p>blogs</p>
//           </a>

//           </div>
//           <div className="sb_footer-links_div">
//         <h4>Coming soon on</h4>
//         <div className="socialmedia"></div>
//        <p><img src={fb} alt=""/></p>
//        <p><img src={tiwtter} alt=""/></p>
//        <p><img src={insta} alt=""/></p>
//           </div>
//       </div>

//     </div>

// <hr></hr>
// <div className="sb_footer-below">
//   <div>
//     @{new Data().getFullYear()} CodeInn.all right
//   </div>
// <div className="sb_footer-below-links">
//   <a herf="/terms"><div><p>Terms and conditions</p></div></a>
//   <a herf="/terms"><div><p>privacy</p></div></a>
//   <a herf="/terms"><div><p>Security</p></div></a>

//   </div>
// </div>
//   </div>

// )
// }

// export default Footer;
